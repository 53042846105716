/**
 * @module app.events.ThemesEventType
 */
/**
 * @enum {string}
 */
const exports = {
  LOAD: 'load'
};


export default exports;
